import React from "react";
import "../styles/App.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import contact from "../images/img-10.jpg";

export default function Contact() {
  return (
    <>
      <NavBar
        title="Contact Us | Point Blank Moving"
        description="Point Blank Moving the best local moving company in KC. Kansas City's premier, five star moving company! Contact us here to for questions or concerns."
      />
      <div
        className="contact-container"
        style={{
          backgroundImage: `url(${contact})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            maxWidth: "fit-content",
            borderRadius: "20px",
          }}
        >
          <h3 className="contact-header">Contact</h3>
          <br />
          <div className="contact-info">
            <a style={{ color: "white" }} href="tel:+1-816-506-8304">
              816-506-8304
            </a>
            <br />
            <br />
            <a
              style={{ color: "white" }}
              href={"mailto:pointblankmoving@gmail.com"}
            >
              pointblankmoving@gmail.com
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
